/* eslint-disable */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { Card, CardContent, Typography, makeStyles } from '@material-ui/core'
import {
  Alert,
  Button,
  Snackbar,
  TextInput
} from '@minha-biblioteca/saraiva-ui'
import queryString from 'query-string'
import Bugsnag from '@bugsnag/js'

import BackLink from '../../components/BackLink'
import services from '../../services'
import useStyles from './styles'
import activateTicketImg from './activateTicket.svg'
import { redirectToLogin, isUnauthorized } from '../../auth'

export default function ActivateTicket() {
  const classes = useStyles()
  const [ticket, setTicket] = useState('')
  const [notifications, setNotifications] = useState([])
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const searchValues = queryString.parse(window.location.search)

  useEffect(() => {
    services.Trackings.visitTicketPage()
  }, [])

  const createErrorNotifications = (errors) => {
    let marginTopDesktop = 88
    let marginTopMobile = 72

    const errorKeys = Object.keys(errors)

    const notificationProperties = errorKeys.map((key) => {
      const styleProps = {
        marginTopDesktop,
        marginTopMobile
      }

      const style = makeStyles((theme) => ({
        root: (props) => ({
          top: 0,
          right: 0,
          marginTop: props.marginTopDesktop,
          marginRight: 8,
          [theme.breakpoints.down(835)]: {
            marginTop: props.marginTopMobile,
            marginRight: 16
          }
        })
      }))

      marginTopDesktop += 68
      marginTopMobile += 84

      services.Trackings.ticketActivation({
        ticket,
        status: 'Erro',
        errorMessage: 'Cupom inválido ou expirado.',
        isFirstActivation: !!searchValues['no-access']
      })

      return {
        show: true,
        message: 'Cupom inválido ou expirado.',
        severity: 'error',
        classes: () => style(styleProps)
      }
    })

    return notificationProperties
  }

  const activateTicket = async () => {
    const payload = {
      user_id: services.Users.getUserId(),
      code: ticket,
      activated_at: new Date()
        .toISOString()
        .replace(/T/, ' ')
        .replace(/\..+/, '')
    }

    let snackbars = []

    setLoading(true)

    const style = makeStyles((theme) => ({
      root: {
        top: 0,
        right: 0,
        marginTop: 88,
        marginRight: 8,
        [theme.breakpoints.down(835)]: {
          marginTop: 72,
          marginRight: 16
        }
      }
    }))

    try {
      const response = await services.Tickets.activateTicket(payload)

      if (isUnauthorized(response.status)) {
        redirectToLogin()
      }

      if (!response.ok) {
        const { errors } = await response.json()

        snackbars = createErrorNotifications(errors)
      } else {
        snackbars = [
          {
            show: true,
            message: 'Cupom ativado com sucesso.',
            severity: 'success',
            classes: () => style()
          }
        ]

        services.Trackings.ticketActivation({
          ticket,
          status: 'Ativado',
          isFirstActivation: !!searchValues['no-access']
        })

        setTimeout(() => {
          setRedirect(true)
        }, 3000)
      }
    } catch (err) {
      Bugsnag.notify(err)

      snackbars = [
        {
          show: true,
          message: 'Ocorreu um erro.',
          severity: 'error',
          classes: () => style()
        }
      ]
    } finally {
      setNotifications(snackbars)
      setLoading(false)
    }
  }

  const closeSnackbar = (notification) => {
    const openNotifications = notifications.filter(
      (notif) => notif.classes !== notification.classes
    )

    setNotifications(openNotifications)
  }

  const renderBackLink = () => {
    if (searchValues['no-access']) {
      return null
    }

    return <BackLink />
  }

  const redirectToHome = () => <Redirect to="/" />

  const renderPageContent = () => {
    return (
      <div className={classes.main}>
        {notifications.map((notification) => (
          <Snackbar
            show={notification.show}
            severity={notification.severity}
            handleClose={() => closeSnackbar(notification)}
            classes={notification.classes}
            key={notification.classes}
            autoHideDuration={3000}
          >
            {notification.message}
          </Snackbar>
        ))}

        {renderBackLink()}

        <Card variant="outlined" className={classes.root}>
          <CardContent className={classes.wrapper}>
            <div className={classes.mainContent}>
              <Typography className={classes.title}>
                Bem-vindo ao Leme!
              </Typography>

              <Typography variant="body1" className={classes.description}>
                Insira o cupom repassado pela administração da sua Biblioteca
                Digital e acesse os livros do Leme.
              </Typography>

              <div className={classes.alert}>
                <Alert severity="info">
                  Caso você não tenha um código de cupom, entre em contato com a
                  administração de sua Biblioteca Digital.
                </Alert>
              </div>

              <TextInput
                color="primary"
                size="full"
                label="Insira o código de cupom"
                onChange={(evt) => setTicket(evt.target.value)}
                value={ticket}
              />

              <div className={classes.button}>
                <Button
                  color="primary"
                  disabled={ticket.length === 0 || loading}
                  variant="contained"
                  onClick={activateTicket}
                  fullWidth
                >
                  Ativar Cupom
                </Button>
              </div>
            </div>

            <div className={classes.imageContainer}>
              <img
                src={activateTicketImg}
                alt="Figura de uma mulher sentada em uma cadeira utilizando um computador"
              />
            </div>
          </CardContent>
        </Card>
      </div>
    )
  }

  return redirect ? redirectToHome() : renderPageContent()
}

ActivateTicket.propTypes = {
  marginTopDesktop: PropTypes.number,
  marginTopMobile: PropTypes.number
}

ActivateTicket.defaultProps = {
  marginTopDesktop: 24,
  marginTopMobile: 24
}
